import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { title } from "../config";
import { getSiteData } from "../services/siteAPI";
import { Alert } from "reactstrap";
import { useNavigate } from "react-router";
import moment from "moment";

const renderVersions = (versionObj, urlPoint) => {
  const keys = Object.keys(versionObj).sort((a, b) => b.localeCompare(a));
  return (
    <>
      <table className="" style={{ width: "100%" }}>
        <tbody>
          {keys.map((keyName, index) => {
            const link = `/sites/${urlPoint}/` + keyName;
            const { count, date } = versionObj[keyName];
            const formattedDate = new Date(date).toLocaleDateString('en-GB') !== "Invalid Date" ? new Date(date).toLocaleDateString('en-GB') : "NA";
            return (
              <tr key={index}>
                <td>
                  <a className="alert-link" href={link} rel="noreferrer">
                    {keyName}
                  </a>({count})
                </td>
                <td>{formattedDate}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

    </>
  );
};

const renderObjArray = (objArray, urlPoint) => {
  const keys = Object.keys(objArray).map(key => isNaN(key) ? key : Number(key)).sort((a, b) =>
    isNaN(a) ? a.localeCompare(b) : b - a
  );
  return (
    <>
      {keys.slice(0, 10).map((keyName, index) => {
        const link = `/sites/${urlPoint}/` + keyName;
        const { count, name } = objArray[keyName];
        return (
          <div key={index} className="d-flex">
            <div className="p-0 m-0 w-3">
              <a className="alert-link" href={link}
                rel="noreferrer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-delay="0"
                title={name} style={{ whiteSpace: "nowrap" }}>
                {(urlPoint === "diffPM2ErrSize" || urlPoint === "diffPM2OutSize") ?
                  `${(keyName / (1024 ** 2)).toFixed(2)} MB ` : `${keyName}`
                }
              </a>
            </div>
            <div className="px-1">({count})</div>
            <div className="ps-5" style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              justifyContent: "start"
            }}>
              {name}
            </div>
          </div>
        );
      })}
    </>
  );
};


const objectsRender = (object, urlPoint) => {
  const keys = Object.keys(object).map(key => isNaN(key) ? key : Number(key)).sort((a, b) =>
    isNaN(a) ? a.localeCompare(b) : b - a
  );
  const values = keys.map(key => object[key]);
  const topKeys = keys.slice(0, 10);
  const topValues = values.slice(0, 10);
  return (
    <>
      {topKeys.map((key, index) => {
        const link = `/sites/${urlPoint}/` + key;
        return (
          <div key={index}>
            <a className="alert-link" href={link} rel="noreferrer">
              {/* {key} */}
              {urlPoint == "diffPM2ErrSize" ?
                `${(key / (1024 ** 2)).toFixed(2)} MB` : `${key}`
              }
            </a>:
            ({topValues[index]})
          </div>
        );
      })}
    </>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [activeOldCount, setActiveOldCount] = useState(0);
  const [inActive, setInActive] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [cmsErrorCount, setCMSErrorCount] = useState(0);
  const [versionObj, setVersionObj] = useState(0);
  const [cmsVersionObj, setCmsVersionObj] = useState(0);
  const [adminVersionObj, setAdminVersionObj] = useState(0);
  const [customVersionObj, setCustomVersionObj] = useState(0);
  const [reportVersionObj, setReportVersionObj] = useState(0);
  const [emailEmpty, setEmailEmpty] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [execFork, setExecFork] = useState(0);
  const [cmsActiveNumber, setCmsActiveNumber] = useState(0);
  const [pm2ErrorObj, setPm2ErrorObj] = useState({});
  const [pm2OutObj, setPm2OutObj] = useState({});
  const [nodeVersionObj, setNodeVersionObj] = useState({});
  const [regionObj, setRegionObj] = useState({});
  const [loader, setLoader] = useState(true);
  const [redisKeysCount, setRedisKeysCount] = useState({});
  const [expDateCounts, setExpDateCounts] = useState({
    expSevenDay: 0,
    expTwoDay: 0,
    expFifteenDay: 0,
  });
  const [domainExpiryCount, setDomainExpiryCount] = useState({
    expSevenDay: 0,
    expTwoDay: 0,
    expFifteenDay: 0,
  });
  const [login,setLogin] = useState({
    last7daysCount:0
  });
  const [memoryFull, setMemoryFull] = useState({
    mem80per : 0,
    mem75per : 0,
  });
  let versionCount = {};
  let cmsVersionCount = {};
  let adminVersionCount = {};
  let customVersionCount = {};
  let reportVersionCount = {};

  const createCustomObject = (customObj, keyObj, extraKey, keyName) => {
    if (keyObj === "") {
      keyObj = "NA";
    }
    if (customObj[keyObj]) {
      customObj[keyObj].count += 1;
    } else {
      customObj[keyObj] = {
        count: 1,
        [extraKey]: keyName ? keyName : "NA",
      };
    }
  };

  const updateVersionCount = (versionObj, version, updatedDate, domain) => {
    if (version === "") {
      version = "NA";
    }
    if (versionObj[version]) {
      versionObj[version].count += 1;
    } else {
      versionObj[version] = {
        count: 1,
        date: updatedDate ? updatedDate : "NA",
      };
    }
  };
  const countFieldValues = ((value, countMap) => {
    if (value) {
      if (countMap.hasOwnProperty(value)) {
        countMap[value]++;
      } else {
        countMap[value] = 1;
      }
    }
  }
  );
  const getFreeSpace = (free, size) => {
    const percentageFull = ((size - free) / size) * 100;
    const roundValue = Math.round(percentageFull);
    return roundValue;
  };
  useEffect(() => {
    reloadSite();
  }, []);
  const reloadSite = async () => {
    let temp = await getSiteData();
    setLoader(false);
    let activeCount = 0;
    let inActiveCount = 0;
    let activeOldCount = 0;
    let errorCount = 0;
    let cmsErrorCount = 0;
    let emailNullCount = 0;
    let emailCount = 0;
    let expDateCount = 0;
    let expTowDays = 0;
    let memoryLowCount = 0;
    let execForkCount = 0;
    let cmsActiveCount = 0;
    let expFifteenDays=0;
    let memory75PerCount = 0;
    let domainExpTwoDays = 0;
    let domainExpSevenDays = 0;
    let domainExpFifteenDays = 0;
    const pm2ErrorDiffcount = {};
    const pm2OutDiffcount = {};
    const nodeVcount = {};
    const regionCount = {};
    let redisKeyCountObj = {};
    let loginCounts={
      last7days:0
    };
    temp.map(
      ({
        active,
        cms,
        status,
        version,
        exDate,
        email,
        free,
        size,
        exec_mode,
        cmsActive,
        newCmsVersion,
        adminVersion,
        customVersion,
        diffPM2ErrSize,
        domain,
        node_version,
        region,
        date,
        name,
        redisKeyCount,
        diffPM2OutSize,
        reportVersion,
        domainExpiry
      }) => {
        if (active == "1" || active == "2") {
          if (cms == "error") {
            cmsErrorCount++;
          }
        }
        if (active == 0) {
          inActiveCount++;
        }
        if (active == 2) {
          activeOldCount++;
        }
        if (active == 1) {
          activeCount++;
          updateVersionCount(versionCount, version, date);
          if (status == "error") {
            errorCount++;
          }
          if (moment(exDate) <= moment(new Date()).add(7, "d")) {
            expDateCount++;
          }
          if (moment(exDate) <= moment(new Date()).add(2, "d")) {
            expTowDays++;
          }
          if (moment(exDate) <= moment(new Date()).add(15, "d")) {
            expFifteenDays++;
          }
          if (domainExpiry!==undefined && moment(domainExpiry) <= moment(new Date()).add(7, "d")) {
            domainExpSevenDays++;
          }
          if (domainExpiry!==undefined && moment(domainExpiry) <= moment(new Date()).add(2, "d")) {
            domainExpTwoDays++;
          }
          if (domainExpiry!==undefined && moment(domainExpiry) <= moment(new Date()).add(15, "d")) {
            domainExpFifteenDays++;
          }
          if (getFreeSpace(free, size) > 80) {
            memoryLowCount++;
          }
          if (getFreeSpace(free, size) > 75) {
            memory75PerCount++;
          }
        }
        if (exec_mode == "fork_mode") {
          execForkCount++;
        }
        if (email == "") {
          emailNullCount++;
        }
        if (email !== "") {
          emailCount++;
        }
        if (diffPM2ErrSize && diffPM2ErrSize >= 0) {
          createCustomObject(pm2ErrorDiffcount, diffPM2ErrSize, "name", name);
          // countFieldValues(diffPM2ErrSize, pm2ErrorDiffcount);
        }
        if (diffPM2OutSize && diffPM2OutSize >= 0) {
          createCustomObject(pm2OutDiffcount, diffPM2OutSize, "name", name);
          // countFieldValues(diffPM2ErrSize, pm2ErrorDiffcount);
        }
        if (node_version) {
          countFieldValues(node_version, nodeVcount);
        }
        if (region) {
          countFieldValues(region, regionCount);
        }
        if(reportVersion?.reportStatus?.reportNotWorking==true){
          loginCounts.last7days++
        }
        if (redisKeyCount) {
          createCustomObject(redisKeyCountObj, redisKeyCount, "name", name);
        }
        if (cmsActive == "1") {
          cmsActiveCount++;
          if (newCmsVersion) {
            updateVersionCount(cmsVersionCount, newCmsVersion?.version, newCmsVersion?.date);
            let adminv = [];
            adminv = adminVersion?.version;
            if (adminv == undefined) {
              adminv = adminVersion?.versionAdmin;
            }
            updateVersionCount(adminVersionCount, adminv, adminVersion?.date);
          }
          if (customVersion) {
            updateVersionCount(customVersionCount, customVersion?.version ? customVersion?.version : customVersion?.customVersion, customVersion?.date, domain);
          }
          if(reportVersion){
            updateVersionCount(reportVersionCount, reportVersion?.version ? reportVersion?.version : reportVersion?.version, reportVersion?.date, domain);
        
          }
        }
      }
    );
    setVersionObj(versionCount);
    setCmsVersionObj(cmsVersionCount);
    setAdminVersionObj(adminVersionCount);
    setCustomVersionObj(customVersionCount);
    setReportVersionObj(reportVersionCount);
    setPm2ErrorObj(pm2ErrorDiffcount);
    setPm2OutObj(pm2OutDiffcount)
    setNodeVersionObj(nodeVcount);
    setActiveCount(activeCount);
    setInActive(inActiveCount);
    setErrorCount(errorCount);
    setExpDateCounts({
      ...expDateCount,
      expTwoDay:expTowDays,
      expSevenDay: expDateCount, 
      expFifteenDay:expFifteenDays
    });
    setDomainExpiryCount({
      ...domainExpiryCount,
      expTwoDay: domainExpTwoDays,
      expSevenDay: domainExpSevenDays,
      expFifteenDay: domainExpFifteenDays
    });
    setEmailEmpty(emailNullCount);
    setEmailCount(emailCount);
    setCMSErrorCount(cmsErrorCount);
    setTotal(temp.length);
    setActiveOldCount(activeOldCount);
    setMemoryFull({...memoryFull, 
      mem80per:memoryLowCount,
      mem75per:memory75PerCount
    });
    setExecFork(execForkCount);
    setCmsActiveNumber(cmsActiveCount);
    setRegionObj(regionCount);
    setRedisKeysCount(redisKeyCountObj);
    setLogin(loginCounts);
  };
  return (
    <div>
      <div className="px-2">
        <h3 className="m-0 p-2">{title} | Home</h3>
        <AppMenu />
      </div>

      {loader ?
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        : <div>
          <div className="row m-lg-1">
            <div className="col-md-3 col-sm-12">
              <Alert color="primary" className="p-3">
                Total
                <a className="alert-link" href="/site" rel="noreferrer">
                  {" "}
                  {total}
                </a>{" "}
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="success" className="p-3">
                Proxy Active New
                <a className="alert-link" href="/sites/active/1" rel="noreferrer">
                  {" "}
                  {activeCount}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="success" className="p-3">
                CMS Active New
                <a className="alert-link" href="/sites/cmsActive/1" rel="noreferrer">
                  {" "}
                  {cmsActiveNumber}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="secondary" className="p-3">
                Inactive
                <a className="alert-link" rel="noreferrer" href="/sites/active/0">
                  {" "}
                  {inActive}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3">
                Proxy Error
                <a
                  className="alert-link"
                  href="/sites/status/error"
                  rel="noreferrer"
                >
                  {" "}
                  {errorCount}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3">
                CMS Error
                <a className="alert-link" href="/sites/cms/error" rel="noreferrer">
                  {" "}
                  {cmsErrorCount}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3">
                SSL Expiry
                <a className="alert-link" rel="noreferrer" href="/sites/expiry/2">
                  {" "}
                  {expDateCounts?.expTwoDay}{" "}
                </a>
                in 2 days.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3">
                Memory 80% full on
                <a className="alert-link" rel="noreferrer" href="sites/free/80">
                  {" "}
                  {memoryFull?.mem80per}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3">
                Domain Expiry
                <a className="alert-link" rel="noreferrer" href="/sites/domain-expiry/2">
                  {" "}
                  {domainExpiryCount?.expTwoDay}{" "}
                </a>
                in 2 days.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3">
              Inactive Reports&nbsp;
                <a className="alert-link" rel="noreferrer" href="/sites/reports-not-working/7">{login?.last7days}
                </a>&nbsp;
                more than 7 days
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3">
                SSL Expiry
                <a className="alert-link" rel="noreferrer" href="/sites/expiry/7">
                  {" "}
                  {expDateCounts?.expSevenDay}{" "}
                </a>
                in 7 days.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3">
                Domain Expiry
                <a className="alert-link" rel="noreferrer" href="/sites/domain-expiry/7">
                  {" "}
                  {domainExpiryCount?.expSevenDay}{" "}
                </a>
                in 7 days.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3">
                SSL Expiry
                <a className="alert-link" rel="noreferrer" href="/sites/expiry/15">
                  {" "}
                  {expDateCounts?.expFifteenDay}{" "}
                </a>
                in 15 days.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3">
                Domain Expiry
                <a className="alert-link" rel="noreferrer" href="/sites/domain-expiry/15">
                  {" "}
                  {domainExpiryCount?.expFifteenDay}{" "}
                </a>
                in 15 days.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3">
                Memory 75% full on
                <a className="alert-link" rel="noreferrer" href="sites/free/75">
                  {" "}
                  {memoryFull?.mem75per}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3">
                Exec mode fork on
                <a
                  className="alert-link"
                  href="/sites/exec_mode/1"
                  rel="noreferrer"
                >
                  {" "}
                  {execFork}{" "}
                </a>
                sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="alert alert-warning" className="p-3">
                Active Old
                <a className="alert-link" href="/sites/active/2" rel="noreferrer">
                  {" "}
                  {activeOldCount}{" "}
                </a>
                Sites.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="alert alert-info" className="p-3">
                <a className="alert-link" href="/sites/email/1" rel="noreferrer">
                  {" "}
                  {emailCount}{" "}
                </a>
                Email contacts are there.
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="alert alert-dark" className="p-3">
                <a className="alert-link" href="/sites/email/0" rel="noreferrer">
                  {" "}
                  {emailEmpty}{" "}
                </a>
                Email contacts are empty.
              </Alert>
            </div>
          </div>
          <div className="row m-lg-1">
            <div className="col-md-3 col-sm-12">
              <Alert color="primary" className="p-3 pe-0">
                Proxy's  {Object.keys(versionObj)?.length} Different  Verison
                <div style={{ height: "190px", overflow: "auto" }}>
                  {renderVersions(versionObj, 'version')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="primary" className="p-3 pe-0">
                {/* Client 18 different Versions: */}
                Client's {Object.keys(cmsVersionObj)?.length} Different  Verison
                <div className="scroll-container" style={{ height: "auto", maxHeight: "190px", overflow: "auto" }}>
                  {/* {console.log("cmsVersionObj:>>", cmsVersionObj)} */}
                  {renderVersions(cmsVersionObj, 'newCmsVersion')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="primary" className="p-3 pe-0">
                Admin's  {Object.keys(adminVersionObj)?.length} Different  Verison
                <div style={{ height: "190px", overflow: "auto" }}>
                  {renderVersions(adminVersionObj, 'adminVersion')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="primary" className="p-3 pe-0">
                CMS {Object.keys(customVersionObj)?.length} Different  Verison
                <div style={{ height: "190px", overflow: "auto" }}>
                  {renderVersions(customVersionObj, 'customVersion')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3 pe-0">
                PM2 Errorlog Significant Difference
                <div style={{ height: "190px", overflowX: "auto", overflowY: "auto" }}>
                  {/* {console.log("pm2ErrorObj",pm2ErrorObj)} */}
                  {renderObjArray(pm2ErrorObj, 'diffPM2ErrSize')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3 pe-0">
                PM2 Outlog Significant Difference
                <div style={{ height: "190px", overflowX: "auto", overflowY: "auto" }}>
                  {/* {console.log("pm2ErrorObj",pm2ErrorObj)} */}
                  {renderObjArray(pm2OutObj, 'diffPM2OutSize')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="danger" className="p-3 pe-0">
                Radis Key Count
                <div style={{ height: "190px", overflowX: "auto", overflowY: "auto" }}>
                  {/* {console.log("pm2ErrorObj",pm2ErrorObj)} */}
                  {renderObjArray(redisKeysCount, 'redisKeyCount')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3 pe-0">
                Node Versions
                <div style={{ height: "190px", overflow: "auto" }}>
                  {objectsRender(nodeVersionObj, 'node_version')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="warning" className="p-3 pe-0">
                Region List
                <div style={{ height: "190px", overflow: "auto" }}>
                  {objectsRender(regionObj, 'region')}
                </div>
              </Alert>
            </div>
            <div className="col-md-3 col-sm-12">
              <Alert color="primary" className="p-3 pe-0">
                {/* Report 18 different Versions: */}
                Report's {Object.keys(reportVersionObj)?.length} Verison
                <div className="scroll-container" style={{ height: "auto", maxHeight: "190px", overflow: "auto" }}>
                  {/* {console.log("cmsVersionObj:>>", cmsVersionObj)} */}
                  {renderVersions(reportVersionObj, 'reportVersion')}
                </div>
              </Alert>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
export default Home;
