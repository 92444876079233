import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { deleteSite, getSiteData } from "../services/siteAPI";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import moment from "moment";
import { title } from "../config";
import SiteListTable from "./SiteListTable";
// import { siteActiveInactive } from "../services/constant";

export function Site() {
  const navigate = useNavigate();
  const { field, value } = useParams();
  const [pageTitle, setPageTitle] = useState();
  const [items, setItems] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [filter, setFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
 
  const getAddButton = (filter, navigate) => {
    if (!filter) {
      return (
        <button
          className="fa-sharp fa-plus btn btn-outline-primary"
          onClick={() => {
            navigate("/site/add");
          }}
          variant="contained"
        > Add </button>
      );
    }
  };
  const getFreeSpace = (free, size) => {
    const percentageFull = ((size - free) / size) * 100;
    const roundValue = Math.round(percentageFull);
    return roundValue;
  };

  const reloadSite = async () => {
    let isFilter = false;
    let records = await getSiteData();
    if (value) {
      isFilter = true;
      setFilter(true);
    }
    if (isFilter && field === "version") {
      records = records.filter(
        (record) => record[field] == value && record.active == 1
      );
      setPageTitle(`Proxy Versions ${value}`);
    }

    if (field === "newCmsVersion") {
      records = records.filter(
        (record) => {
          if (record.cmsActive == 1) {
            return record?.newCmsVersion?.version == value;
          }
        }
      );
      setPageTitle(`Client Versions ${value}`);
    }
    if (field === "customVersion") {
      records = records.filter(
        (record) => {
          if (record.cmsActive == 1) {
            return record?.customVersion?.version == value;
          }
        }
      );
      setPageTitle(`CMS Versions ${value}`);
    }
    if (field === "reportVersion") {
      records = records.filter(
        (record) => {
          if (record.cmsActive == 1) {
            return record?.reportVersion?.version == value;
          }
        }
      );
      setPageTitle(`Report Versions ${value}`);
    }
    if (field === "reports-not-working") {
      records = records.filter((record) => record.cmsActive == 1 && record?.reportVersion?.reportStatus?.reportNotWorking && record.reportVersion.reportStatus.notWorkingDays >= value);
      setPageTitle(`Report Inactivity for ${value} days`);
    }
    if (field == "diffPM2ErrSize") {
      records = records.filter((record) => record[field] == Number(value));
      // records = records.filter(record => record?.[field] === value);
      setPageTitle("PM2 Error Logs Significant Difference");
    }
    if (field == "diffPM2OutSize") {
      records = records.filter((record) => record[field] == Number(value));
      setPageTitle("PM2 Out Logs Significant Difference");
    }
    if (field === "adminVersion") {
      records = records.filter(
        (record) => {
          if (record.cmsActive == 1) {
            return (record?.adminVersion?.version == value || record?.adminVersion?.versionAdmin == value);
          }
        }
      );
      setPageTitle(`Admin Versions ${value}`);
    }
    //cms-active/1 
    else if (isFilter && field === "cmsActive") {
      records = records.filter((record) => record[field] == Number(value));
      if (field == "cmsActive" && value == 1) {
        setPageTitle("New CMS Active Sites");
      }
    }
    // active inactive
    else if (isFilter && field === "active") {
      records = records.filter((record) => record[field] == Number(value));
      if (field == "active" && value == 0) {
        setPageTitle("Inactive Sites");
      }
      if (field == "active" && value == 1) {
        setPageTitle("Active Sites");
      }
    }
    //error cms & proxy
    else if (isFilter && field !== "expiry") {
      if (field === "status" && value === "error") {
        records = records.filter(
          (record) => record[field] == value && record.active == 1
        );
        setPageTitle("Proxy Error Sites");
      } else if (field === "cms" && value === "error") {
        records = records.filter(
          (record) => record[field] == value && record.active > 0
        );
        setPageTitle("CMS Error Sites");
      }
      if (field === "email" && value === "0") {
        records = records.filter((record) => record[field] === "");
        setPageTitle("Email Null Sites");
      }
      if (field === "email" && value === "1") {
        records = records.filter((record) => record[field] !== "");
        setPageTitle("Contact Email Sites");
      }
      if (field === "free") {
        records = records.filter(
          (record) => getFreeSpace(record[field], record.size) > value && record.active == 1
        );
        setPageTitle(`Memory ${value} % full Sites`, records);
      }
      if (field === "exec_mode") {
        records = records.filter((record) => record[field] == "fork_mode");
        setPageTitle("Fork mode execution sites");
      }
      if (field == "node_version") {
        records = records.filter((record) => record[field] == value);
        setPageTitle(`Node Versions ${value}`);
      }
      if (field === "region") {
        records = records.filter((record) => record[field] == value);
        setPageTitle(`Region ${value}`);
      }
      if (field === "redisKeyCount") {
        records = records.filter((record) => record[field] == value);
        setPageTitle(`Redis Key Count ${value}`);
      }
    }
    if (isFilter && field === "domain-expiry") {
      records = records.filter((record) => record.active == 1);
      records = records.filter(
        (record) => record.domainExpiry !== undefined && moment(record.domainExpiry).isSameOrBefore(moment().add(value, "days"))
      );
      setPageTitle(`Domain Expiry Sites In ${value} Days`, records);
    }
    //certificate expiry
    else if (isFilter && field === "expiry") {
      records = records.filter((record) => record.active == 1);
      records = records.filter(
        (record) => moment(record.exDate) <= moment(new Date()).add(value, "d")
      );
      setPageTitle(`Certificate Expiry Sites In ${value} Days`, records);
    }
    setAllSites(records);
    setLoading(false);
    setItems(records);
  };
  const doDelete = async (_id) => {
    await deleteSite(_id);
    reloadSite();
  };
  const doUpgrade = (link) => {
    const upgradeUrl = `https://core.${link}/check-update`;
    return upgradeUrl;
  };
  const doHistory = (id) => {
    navigate(`/state/${id}`);
  };
  const doEdit = (id) => {
    navigate(`/site/edit/${id}`);
  };
  const onSearchChange = (e) => {
    const text = e.target.value.toLowerCase();
    const searchResult = allSites.filter((record) => {
      if (record.name.toLowerCase().startsWith(text)) {
        return true;
      }
      if (record.domain.toLowerCase().startsWith(text)) {
        return true;
      }
    });
    setLoading(false);
    setSearchText(e.target.value);
    setItems(searchResult);
  };
  useEffect(() => {
    reloadSite();
    setPageTitle("Sites");
  }, [field]);
  return (
    <div>
      <div className="px-2">
        <h3 className="m-0 p-2">{title} | {pageTitle}</h3>
        <AppMenu />
      </div>
      <div className="p-0 px-2 m-0">
        <div className="px-2">
          <input
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search Site Name"
            type="search"
            className="rounded-4"
          />
          <span className="headerMargin ms-1">{getAddButton(filter, navigate)}</span>
          <span style={{ float: "right", marginRight: "10px" }}>
            <b>Total:{items.length} &nbsp;&nbsp;</b>
          </span>
        </div>
        <hr className="p-0 my-2" />
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "75vh",
          }}
        >
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : items?.length > 0 ? (
        <SiteListTable
          items={items}
          doDelete={doDelete}
          doEdit={doEdit}
          doHistory={doHistory}
          doUpgrade={doUpgrade}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "75vh",
          }}
        >
          <span>No records found...</span>
        </div>
      )}
    </div>
  );
}

// const getStatus = (status) => {
//   let statusSite = siteActiveInactive.find(
//     (item) => item.value === Number(status)
//   );
//   if (statusSite) {
//     return statusSite.name;
//   } else {
//     return "";
//   }
// };

// const getFreeSpace = (free, size) => {
//   const percentageFull = ((size - free) / size) * 100;
//   const roundValue = Math.round(percentageFull);
//   return roundValue;
// };

// const getAddButton = (filter, navigate) => {
//   if (!filter) {
//     return (
//       <button
//         className="fa-sharp fa-plus btn btn-outline-primary"
//         onClick={() => {
//           navigate("/site/add");
//         }}
//         variant="contained"
//       > Add </button>
//     );
//   }
// };
// function SiteList({ items, doDelete, doEdit, doHistory, doUpgrade }) {
//   const [localItems, setlocalItems] = useState([...items]);
//   const [toggleSorting, setToggleSorting] = useState(0);
//   const [dynamicStyles, setDynamicStyles] = useState();

//   useEffect(() => {
//     const styles = `.cursor-pointer
//         { cursor: pointer;}`;
//     setDynamicStyles(styles);
//     setlocalItems(items);
//   }, [items]);

//   const sortTable = (field) => {
//     let sortedData = [];

//     if (toggleSorting === 1) {
//       sortedData = [...localItems].sort((a, b) => a[field] > b[field] ? 1 : -1);
//       setToggleSorting(0);
//     } else {
//       sortedData = [...localItems].sort((a, b) => b[field] > a[field] ? 1 : -1);
//       setToggleSorting(1);
//     }
//     setlocalItems(sortedData);
//   }

//   // const sortTable = (field) => {
//   //   let sortedData = [];
//   //   if (toggleSorting === 1) {
//   //     sortedData = [...localItems].
//   //     sort((a, b) => {
//   //       if (a[field] > b[field]) {
//   //         return -1;
//   //       } else if (a[field] < b[field]) {
//   //         return 1;
//   //       } else {
//   //         return 0;
//   //       }
//   //     });
//   //     setToggleSorting(0);
//   //   } else {
//   //     sortedData = [...localItems].sort((a, b) => {
//   //       if (a[field] < b[field]) {
//   //         return -1;
//   //       } else if (a[field] > b[field]) {
//   //         return 1;
//   //       } else {
//   //         return 0;
//   //       }
//   //     });
//   //     setToggleSorting(1);
//   //   }
//   //   setlocalItems(sortedData);
//   // };

//   return (
//     <div className="p-0 m-0 px-2">
//       <style>{dynamicStyles}</style>
//       <Table className="table table-hover" responsive hover>
//         <thead>
//           <tr>
//             <th onClick={() => sortTable("name")} className="cursor-pointer">Name</th>
//             <th>
//               <span onClick={() => sortTable("cms")} className="cursor-pointer">CMS</span> |
//               <span onClick={() => sortTable("status")} className="cursor-pointer">Proxy</span>
//             </th>
//             <th onClick={() => sortTable("exDate")} className="cursor-pointer">Cert. Exp</th>
//             <th onClick={() => sortTable("domain")} className="cursor-pointer">Domain</th>
//             <th onClick={() => sortTable("exec_mode")} className="cursor-pointer">Ex.mode</th>
//             <th onClick={() => sortTable(getFreeSpace('free', 'size'))} className="cursor-pointer">Memory</th>
//             <th onClick={() => sortTable("osUptime")} className="cursor-pointer">OS Uptime</th>
//             <th onClick={() => sortTable("nodeUptime")} className="cursor-pointer">Proxy Uptime</th>
//             <th>
//               <span onClick={() => sortTable("databases")} className="cursor-pointer">DBs</span> |
//               <span onClick={() => sortTable("redisKeyCount")} className="cursor-pointer"> RedisKey </span>
//             </th>
//             <th>
//               <span onClick={() => sortTable("active")} className="cursor-pointer">P.Active </span> |
//               <span onClick={() => sortTable("cmsActive")} className="cursor-pointer"> CMS </span>
//             </th>
//             <th>Proxy V.</th>
//             <th>CMS V.</th>
//             <th>Admin V.</th>
//             <th onClick={() => sortTable("node_version")} className="cursor-pointer">Node V.</th>
//             <th>I.Type</th>
//             <th>R.Link</th>
//             <th>
//               <span onClick={() => sortTable("item?.siteUserDetails?.activeUserCount")} className="cursor-pointer">A.User</span>|
//               <span onClick={() => sortTable("item?.siteUserDetails?.currentSessionCount")} className="cursor-pointer"> Session </span>
//             </th>
//             <th>
//               <span onClick={() => sortTable("item?.siteUserDetails?.activeUserCount")} className="cursor-pointer">NoSigin</span> |
//               <span onClick={() => sortTable("item?.siteUserDetails?.currentSessionCount")} className="cursor-pointer"> Sometime </span>
//             </th>
//             <th onClick={() => sortTable("region")} className="cursor-pointer">Region</th>
//             <th onClick={() => sortTable("diffPM2ErrSize")} className="cursor-pointer">Diff. Error | Out </th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {localItems.map((item) => (
//             <tr key={item._id}>
//               <td>{item.name}</td>
//               <td> {item.cms}|{item.status}</td>
//               <td>{moment(item.exDate).format("DD/MM/YYYY")}</td>
//               <td>
//                 <a href={`https://${item.domain}`} target="_blank">{item.domain}</a>
//               </td>
//               <td>{item.exec_mode}</td>
//               <td>
//                 {`${item.free
//                   ? getFreeSpace(item.free, item.size) +
//                   "%" +
//                   " (" +
//                   (item.size / 1073741824).toFixed(0) +
//                   "GB)"
//                   : " "
//                   }`}
//               </td>
//               <td>
//                 {item.osUptime
//                   ? Math.round(item.osUptime / (60 * 60 * 24)).toFixed(0)
//                   : " -"}{" "}
//                 Days
//               </td>
//               <td>
//                 {item.nodeUptime
//                   ? Math.round(item.nodeUptime / (60 * 60)).toFixed(0)
//                   : " -"}{" "}
//                 Hrs
//               </td>

//               <td>{item.databases ? item.databases : "-"} | {item.redisKeyCount ? item.redisKeyCount : "-"}</td>
//               <td>{item.active} | {item?.cmsActive}</td>
//               <th>{item?.version} | {item?.date}</th>
//               <th>{item?.newCmsVersion?.version} | {item?.newCmsVersion?.date}</th>
//               <th>{item?.adminVersion?.version} | {item?.adminVersion?.date}</th>
//               <td>{item.node_version ? item.node_version : " "}</td>
//               <td>{item.instituteType}</td>
//               <td>{item.redirectLink ? item.redirectLink : "-"}</td>
//               <td>
//                 {item?.siteUserDetails?.activeUserCount ? item?.siteUserDetails?.activeUserCount : "-"} |
//                 {item?.siteUserDetails?.currentSessionCount ? item?.siteUserDetails?.currentSessionCount : "-"}
//               </td>
//               <td>
//                 {item?.siteUserDetails?.notLoggedInUsers ? item?.siteUserDetails?.notLoggedInUsers : "-"} |
//                 {item?.siteUserDetails?.loginCounLessThanFive ? item?.siteUserDetails?.loginCounLessThanFive : "-"}
//               </td>
//               <td>{item.region ? item.region : "-"}</td>
//               <td>{(item.diffPM2ErrSize / (1024 ** 2)).toFixed(2)}| {(item.diffPM2OutSize / (1024 ** 2)).toFixed(2)} MB</td>
//               <td className="">
//                 <div className="dropdown float-end pe-2">
//                   <a
//                     href="#"
//                     role="button"
//                     data-bs-toggle="dropdown"
//                     aria-expanded="false"
//                   >
//                     <i className="fa-solid fa-bars"></i>
//                   </a>
//                   <ul className="dropdown-menu p-2">
//                     <a href={doUpgrade(item.domain)} target={"_blank"}>
//                       <i
//                         className="fa-solid fa-cloud-arrow-up btn btn-dark m-2 "
//                         aria-hidden="true"
//                       ></i>
//                     </a>
//                     <i
//                       className="fa-solid fa-clock-rotate-left btn btn-warning m-2 "
//                       onClick={() => doHistory(item._id)}
//                     ></i>
//                     <i
//                       className="fas fa-edit btn btn-primary m-2 "
//                       onClick={() => doEdit(item._id)}
//                       aria-hidden="true"
//                     ></i>
//                     <i
//                       className="fa-solid fa-trash-can btn btn-danger m-2"
//                       onClick={() => doDelete(item._id)}
//                       aria-hidden="true"
//                     ></i>
//                   </ul>
//                 </div>
//               </td>
//               <th></th>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// }
